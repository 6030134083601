import CustomDatePicker from './CustomDatePicker.vue'
import InputInvalidMsg from './InputInvalidMsg.vue'
import CustomDropdown from './CustomDropdown.vue'
import FileInput from './formInputs/FileInput.vue'
import PasswordInput from './formInputs/PasswordInput.vue'
import TabItem from './tabMenu/TabItem.vue'
import TabMenu from './tabMenu/TabMenu.vue'
import NovaDeclaracaoBtn from './NovaDeclaracaoBtn.vue'
import DeclaracaoUnicaInput from './DeclaracaoUnicaInput.vue'
import DeclaracaoUnicaBlockedData from './DeclaracaoUnicaBlockedData.vue'
import DeclaracaoUnicaUnseeData from './DeclaracaoUnicaUnseeData.vue'
import EmptyTable from './EmptyTable.vue'
import PedidoRequerimento from './PedidoRequerimento.vue'
import tooltip from './tooltip';
import IconPadlock from './icons/padlock';
import DeclarationColumnTable from './declaration-column-table';
import IconArrowLeft from './icons/arrow-left';
import IconArrowRight from './icons/arrow-right';
import IconDraft from './icons/draft';
import IconEye from './icons/eye';
import DeclarationRestrictData from './declaration-restrict-data';
import DeclarationSectionTitle from './declaration-section-title';
import DeclarationLegalNotice from './declaration-legal-notice';
import Recaptcha from './recaptcha';
import RecaptchaV2 from './recaptcha-v2';
import DeclarationTableRow from './declaration-table-row.vue';
import DecisionCard from './decision-card.vue';
import IconPerson from './person.vue';
//badges
import requirementStatusTypeBadge from './badges/requirement-status-type';

export default { 
    CustomDatePicker,
    InputInvalidMsg,
    CustomDropdown,
    TabMenu,
    TabItem,
    NovaDeclaracaoBtn,
    FileInput,
    DeclaracaoUnicaInput,
    DeclaracaoUnicaBlockedData,
    DeclaracaoUnicaUnseeData,
    EmptyTable,
    PedidoRequerimento,
    PasswordInput,
    tooltip,
    IconPadlock,
    DeclarationColumnTable,
    IconArrowLeft,
    IconArrowRight,
    IconDraft,
    IconEye,
    DeclarationRestrictData,
    DeclarationSectionTitle,
    Recaptcha,
    DeclarationLegalNotice,
    RecaptchaV2,
    DeclarationTableRow,
    DecisionCard,
    IconPerson,
    requirementStatusTypeBadge
 }