<template>
  <div id="app">
    <Page :showPageHeader="!showCustomHeader">
      <template #customPageHeader v-if="showCustomHeader">
        <HomePageHeader />
      </template>
      <template #perfilContent>
        <template v-if="showPerfilInfo">
          <div class="d-f">
            <div
              v-if="showNotifications"
              class="notification-icon btnAlike"
              :class="{ active: isActiveNotification }"
              @click="openCloseNotification"
            >
              <span class="notification-icon-count">{{ count }}</span>
              <svg
                width="23"
                height="26"
                viewBox="0 0 23 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  v-if="!isActiveNotification"
                  d="M7.5625 19.3252V20.6341C7.5625 21.1498 7.66435 21.6605 7.86222 22.1369C8.0601 22.6133 8.35014 23.0462 8.71577 23.4108C9.0814 23.7755 9.51546 24.0647 9.99318 24.2621C10.4709 24.4594 10.9829 24.561 11.5 24.561C12.0171 24.561 12.5291 24.4594 13.0068 24.2621C13.4845 24.0647 13.9186 23.7755 14.2842 23.4108C14.6499 23.0462 14.9399 22.6133 15.1378 22.1369C15.3357 21.6605 15.4375 21.1498 15.4375 20.6341V19.3252M19.375 8.85366C19.375 12.7805 22 19.3252 22 19.3252H1C1 19.3252 3.625 14.0894 3.625 8.85366C3.625 4.57603 7.21075 1 11.5 1C15.7893 1 19.375 4.57603 19.375 8.85366Z"
                  stroke="#fff"
                  stroke-width="1.79268"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  v-else
                  d="M7.5625 19.3252V20.6341C7.5625 21.1498 7.66435 21.6605 7.86222 22.1369C8.0601 22.6133 8.35014 23.0462 8.71577 23.4108C9.0814 23.7755 9.51546 24.0647 9.99318 24.2621C10.4709 24.4594 10.9829 24.561 11.5 24.561C12.0171 24.561 12.5291 24.4594 13.0068 24.2621C13.4845 24.0647 13.9186 23.7755 14.2842 23.4108C14.6499 23.0462 14.9399 22.6133 15.1378 22.1369C15.3357 21.6605 15.4375 21.1498 15.4375 20.6341V19.3252M19.375 8.85366C19.375 12.7805 22 19.3252 22 19.3252H1C1 19.3252 3.625 14.0894 3.625 8.85366C3.625 4.57603 7.21075 1 11.5 1C15.7893 1 19.375 4.57603 19.375 8.85366Z"
                  stroke="#102E40"
                  stroke-width="1.79268"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <PerfilInfo
              :username="username"
              :perfilName="selectedLang.personalData"
              :logOutFunc="logOut"
              :route="
                auth.profiles[0] == enums.userProfileEnum.comission
                  ? 'PerfilComissao'
                  : 'Perfil'
              "
            />
            <notifications />
          </div>
        </template>
      </template>
      <template #breadcrumb v-if="!showCustomHeader">
        <BreadCrumbItem
          v-for="(item, index) in breadCrumb"
          :key="index"
          :href="bindParams(item.to, item.params)"
          :title="selectedLang[item.text]"
        />
      </template>
      <template #content>
        <div @click="closeNotification">
          <router-view />
        </div>
      </template>
      <template #footer>
        <div class="col-2 col-xs-12">
          <span class="text">{{ selectedLang.siteFooter }}</span>
        </div>
        <div class="col-8 col-xs-12" v-if="isTitular">
          <span class="text">
            {{ selectedLang.footerTitularMessage }}
            <a
              target="_blank"
              class="hyperlink"
              href="https://www.tribunalconstitucional.pt/tc/ept/infolegal.html"
            >
              aqui</a
            >
          </span>
        </div>
        <div class="col-2 col-xs-12"></div>
      </template>
    </Page>
  </div>
</template>

<script>
import HomePageHeader from "@/views/home/HomePageHeader.vue";
import notifications from "@/components/notifications.vue";
import { authentication, utils, enums } from "@/helpers";
import { notificationService } from "@/services";

export default {
  name: "App",
  components: { HomePageHeader, notifications },
  data() {
    return {
      authentication: null,
      isActiveNotification: false,
      count: 0,
      urlToShow: [
        "Login",
        "ConsultarDeclaracoes",
        "DeclaracaoUnicaPage",
        "ConsultaDeclaracaoPrivada",
        "Perfil",
        "AreaPrivada",
      ],
    };
  },
  computed: {
    isTitular() {
      return this.urlToShow.includes(this.route.name);
    },
    route() {
      return this.$route;
    },
    breadCrumb() {
      return this.$route.meta.breadCrumb;
    },
    showCustomHeader() {
      if (this.breadCrumb == null || this.breadCrumb == undefined) {
        return true;
      }
      return this.breadCrumb.length == 0;
    },
    auth: {
      get() {
        return this.authentication;
      },
      set(value) {
        this.authentication = value;
      },
    },
    showPerfilInfo() {
      return this.auth != null && !this.auth.expired;
    },
    showNotifications() {
      //TODO: card 1604
      // return (
      //   utils.checkUserProfile(this.auth, enums.userProfileEnum.holder) ||
      //   utils.checkUserProfile(this.auth, enums.userProfileEnum.comission)
      // );
      return utils.checkUserProfile(this.auth, enums.userProfileEnum.holder);
    },
    username() {
      var name = this.auth.name;
      var parts = name.split(" ");

      if (parts.length > 2) {
        return `${parts[0]} ${parts[parts.length - 1]}`;
      } else {
        return name;
      }
    },
  },
  watch: {
    breadCrumb() {
      this.authentication = authentication.get();
    },
  },
  methods: {
    bindParams(to, params) {
      if (params == null || params == undefined || params.length == 0) {
        return to;
      } else {
        let toSplit = to.split("/");

        var index = 0;
        toSplit.forEach((element) => {
          if (element.includes(":")) {
            toSplit[index] = this.route.params[element.substring(1)];
          }
          index++;
        });

        return toSplit.join("/");
      }
    },
    logOut() {
      authentication.set();
      this.$router.push({ name: "HomePage" });
    },
    openCloseNotification() {
      this.isActiveNotification = !this.isActiveNotification;
      this.emitter.emit("OpenClose-Notification");
    },
    closeNotification() {
      this.isActiveNotification = false;
      this.emitter.emit("Close-Notification");
    },
    async countNotification() {
      const auth = authentication.get();
      if (auth && !auth.expired) {
        const response = await notificationService.unreadsCount();

        utils.validateResponse(response, null, () => {
          this.count = response.data;
        });
      }
    },
  },
  mounted() {
    this.emitter.on("Notification:Counter", async () => {
      await this.countNotification();
    });

    this.emitter.on("Perfil:UpdateUsername", () => {
      this.auth = authentication.get();
    });
  },
  updated() {
    this.countNotification();
  },
};
</script>
