<template>
  <div>
    <div class="row mb-2">
      <div class="col-12 ta-last-left">
        <span class="title d-f"
          >{{ selectedLang.passive }}
          <PublicCantSee guid="ba17cce1-b542-42a8-a22a-d6a541428858" />
        </span>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <PassivoPortugal
          v-if="isPortugal"
          :declaracaoUnicaProp="PassivoPassivo"
        />
        <PassivoEstrangeiro v-else :declaracaoUnicaProp="PassivoPassivo" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <GarantiasPatrimoniais
          :guid="GarantiasPatrimoniaisGuid"
          :declaracaoUnicaProp="GarantiasPatrimoniais"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PassivoEstrangeiro from "./passivo-estrangeiro.vue";
import PassivoPortugal from "./passivo-portugal.vue";
import GarantiasPatrimoniais from "./GarantiasPatrimoniais.vue";

export default {
  components: { PassivoEstrangeiro, PassivoPortugal, GarantiasPatrimoniais },
  props: {
    declaracaoUnicaProp: [Object, Array],
  },
  computed: {
    isPortugal() {
      return (
        this.declaracaoUnicaProp.key == "bf6dcb0a-19de-4c77-8ff6-76e57f93ceef"
      );
    },
    PassivoPassivoGuid() {
      return this.declaracaoUnicaProp.key ==
        "bf6dcb0a-19de-4c77-8ff6-76e57f93ceef"
        ? "27bb57dd-0fd8-4595-aaca-53bf1634ca87"
        : "50117239-0a0b-427d-9c23-f33c766b2b3a";
    },
    PassivoPassivo: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.PassivoPassivoGuid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.PassivoPassivoGuid,
          newValue
        );
      },
    },

    GarantiasPatrimoniaisGuid() {
      return this.declaracaoUnicaProp.key ==
        "bf6dcb0a-19de-4c77-8ff6-76e57f93ceef"
        ? "ffd1a4f7-c19e-4e75-b722-11c6e3fae511"
        : "3737df7d-c0e0-4a24-8bce-346a479971f5";
    },
    GarantiasPatrimoniais: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.GarantiasPatrimoniaisGuid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.GarantiasPatrimoniaisGuid,
          newValue
        );
      },
    },
  },
};
</script>
