<template>
  <CustomAccordion
    :title="selectedLang.attachments"
    :isOpenDefault="items != null && items.length > 0"
  >
    <div>
      <TableSort
        :showEmptyrow="false"
        :totalItems="1"
        defaultSort=""
        :checkBox="false"
        :loading="loading"
        :tableModel="tableModel"
        :actions="canEditDeclaration"
        :searchFunc="searchFunc"
        :sortFunc="sortFunc"
        :showPagination="false"
        :showTotal="false"
        :sortHeader="false"
      >
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ formatBytes(item.size) }}</td>
          <td>{{ getFormatFileName(item.name) }}</td>
          <td>{{ selectedLang.attachmentsOptions[item.sectionType] }}</td>
          <td>{{ item.observations }}</td>
          <td class="d-f" v-if="canEditDeclaration">
            <a class="btnAlike icon trash" @click="download(item)">
              <IconDownload />
            </a>
            <span class="ml-1 mr-1">
              <svg
                width="1"
                height="40"
                viewBox="0 0 1 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="2.18557e-08"
                  x2="0.499998"
                  y2="40"
                  stroke="#D8D8D8"
                />
              </svg>
            </span>
            <span @click="openDeleteDetail(item)" class="btnAlike">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                  stroke="#102E40"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                  stroke="#102E40"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.8494 9.14001L18.1994 19.21C18.0894 20.78 17.9994 22 15.2094 22H8.78941C5.99941 22 5.90941 20.78 5.79941 19.21L5.14941 9.14001"
                  stroke="#102E40"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.3301 16.5H13.6601"
                  stroke="#102E40"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.5 12.5H14.5"
                  stroke="#102E40"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="ml-1 mr-1">
              <svg
                width="1"
                height="40"
                viewBox="0 0 1 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="2.18557e-08"
                  x2="0.499998"
                  y2="40"
                  stroke="#D8D8D8"
                />
              </svg>
            </span>
            <span @click="editItem(item)" class="btnAlike">
              <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 21H21.5"
                  stroke="#102E40"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3845 4.82378C16.0155 3.62383 13.9241 3.74942 12.7122 5.10499C12.7122 5.10499 6.68886 11.8419 4.60018 14.1803C2.50875 16.5174 4.04182 19.746 4.04182 19.746C4.04182 19.746 7.494 20.8285 9.55372 18.5242C11.6148 16.2198 17.6685 9.45019 17.6685 9.45019C18.8804 8.09462 18.7522 6.02372 17.3845 4.82378Z"
                  stroke="#102E40"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.8604 7.29236L15.4255 11.2791"
                  stroke="#102E40"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </td>
        </tr>
        <tr>
          <td
            colspan="11"
            class="p-3 jc-center tal-center"
            v-if="!canEditDeclaration && items.length == 0"
          >
            <h1>
              {{ selectedLang.nothingDeclare }}
            </h1>
          </td>
        </tr>
        <tr v-if="canEditDeclaration">
          <td colspan="11" class="p-0 pt-1">
            <span @click="newItem" class="d-f jc-center btnAlike">
              <svg
                style="margin: 9px 0px"
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.2241 7.57143V6.42857C12.2241 6.27381 12.1658 6.13988 12.0491 6.02678C11.9324 5.91369 11.7942 5.85714 11.6345 5.85714H9.27579V3.57143C9.27579 3.41667 9.21744 3.28274 9.10074 3.16964C8.98403 3.05655 8.84583 3 8.68613 3H7.50679C7.34709 3 7.20889 3.05655 7.09218 3.16964C6.97548 3.28274 6.91713 3.41667 6.91713 3.57143V5.85714H4.55847C4.39877 5.85714 4.26056 5.91369 4.14386 6.02678C4.02715 6.13988 3.9688 6.27381 3.9688 6.42857V7.57143C3.9688 7.72619 4.02715 7.86012 4.14386 7.97322C4.26056 8.08631 4.39877 8.14286 4.55847 8.14286H6.91713V10.4286C6.91713 10.5833 6.97548 10.7173 7.09218 10.8304C7.20889 10.9435 7.34709 11 7.50679 11H8.68613C8.84583 11 8.98403 10.9435 9.10074 10.8304C9.21744 10.7173 9.27579 10.5833 9.27579 10.4286V8.14286H11.6345C11.7942 8.14286 11.9324 8.08631 12.0491 7.97322C12.1658 7.86012 12.2241 7.72619 12.2241 7.57143ZM14.3511 3.49089C14.9969 4.56033 15.3199 5.73004 15.3199 7C15.3199 8.26996 14.9969 9.44119 14.3511 10.5137C13.7052 11.5862 12.829 12.4353 11.7223 13.0612C10.6156 13.6871 9.40695 14 8.09645 14C6.78596 14 5.57735 13.6871 4.47064 13.0612C3.36393 12.4353 2.48765 11.5862 1.84181 10.5137C1.19597 9.44119 0.873047 8.26996 0.873047 7C0.873047 5.73004 1.19597 4.55881 1.84181 3.48633C2.48765 2.41385 3.36393 1.56467 4.47064 0.938802C5.57735 0.312934 6.78596 0 8.09645 0C9.40695 0 10.6156 0.312934 11.7223 0.938802C12.829 1.56467 13.7052 2.41536 14.3511 3.49089Z"
                  fill="#007AC4"
                />
              </svg>
              <p class="declarationRow">
                {{ selectedLang.adding }}
              </p>
            </span>
          </td>
        </tr>
      </TableSort>

      <CustomModal
        modalId="novaDeclaracaoAnexosEdit"
        size="xl"
        :title="modalTitle"
      >
        <div class="row mt-4">
          <div class="col-12 col-md-6 mb-4">
            <CustomInput
              type="Multiselect"
              :label="selectedLang.informationBlock"
              v-model="itemToEdit.sectionType"
              :required="true"
              :options="attachmentsOptions"
              :showErrorMsg="sectionTypeShowError"
            />
          </div>
          <div class="col-12 col-md-6 mb-4">
            <CustomInput
              type="Text"
              :label="selectedLang.comments"
              v-model="itemToEdit.observations"
              :required="false"
              :maxlength="60"
            />
          </div>
          <div class="col-12 mb-4">
            <CustomInput
              :tooltipInfo="
                selectedLang.permitedFileType +
                '.doc,.docx,.pdf,.xlxs,.xls' +
                selectedLang.permitedFileSize
              "
              type="File"
              :label="selectedLang.attachment"
              v-model="itemToEdit.anexo"
              :required="true"
              :showErrorMsg="anexoShowError"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-f jc-right">
            <button
              v-if="!newItemFlag"
              type="button"
              @click="openDeleteDetail(itemToEdit)"
              class="btn btnIcon btnSecundary mr-1"
            >
              <svg
                width="30"
                height="28"
                viewBox="0 0 30 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.25 6.97664C22.0875 6.59164 17.9 6.39331 13.725 6.39331C11.25 6.39331 8.775 6.50998 6.3 6.74331L3.75 6.97664"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.625 5.79834L10.9 4.27C11.1 3.16167 11.25 2.33334 13.3625 2.33334H16.6375C18.75 2.33334 18.9125 3.20834 19.1 4.28167L19.375 5.79834"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M23.5625 10.6633L22.75 22.4117C22.6125 24.2433 22.5 25.6667 19.0125 25.6667H10.9875C7.5 25.6667 7.3875 24.2433 7.25 22.4117L6.4375 10.6633"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.9121 19.25H17.0746"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.875 14.5833H18.125"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              type="button"
              @click="saveItem"
              class="btn btnS btnPrimary mr-1"
            >
              {{ selectedLang.adding }}
            </button>
            <button type="button" @click="cancel" class="btn btnS btnSecundary">
              {{ selectedLang.cancel }}
            </button>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        modalId="novaDeclaracaoAnexosDelete"
        size="md"
        :isCenter="true"
        :title="selectedLang.attachmentsModalDelete"
      >
        <p class="mt-4 mb-4">
          {{ selectedLang.deleteQuestionAnex }} {{ nameToDelete }}?
        </p>
        <div class="d-f pc-center">
          <button
            type="button"
            @click="deleteItem"
            class="btn btnS btnPrimary mr-1"
          >
            {{ selectedLang.eliminate }}
          </button>
          <button type="button" @click="cancel" class="btn btnS btnSecundary">
            {{ selectedLang.cancel }}
          </button>
        </div>
      </CustomModal>
    </div>
  </CustomAccordion>
</template>

<script>
import IconDownload from "@/components/icons/download.vue";
import { attachmentService, declarationService } from "@/services";
import { utils, declaracaoUnicaHelper } from "@/helpers";
import { toRaw } from "vue";

export default {
  components: {
    IconDownload,
  },
  data() {
    return {
      loading: false,
      validateForm: false,
      tableModelAux: {
        total: 200,
        pages: 7,
      },
      modalDetailAux: {
        visible: false,
        size: "xl",
      },
      modalDeleteAux: {
        visible: false,
        size: "lg",
      },
      idToDelete: null,
      nameToDelete: null,
      itemToEdit: {},
      newItemFlag: false,
      items: [],
      itemsToModel: [],
      attachmentsOptions: [],
    };
  },
  props: {
    declaracaoUnicaProp: Object,
  },
  methods: {
    searchFunc() {},
    sortFunc() {},
    openModal(modalId) {
      this.validateForm = false;
      this.emitter.emit("openCustomModal", modalId);
    },
    closeModal(modalId) {
      this.emitter.emit("closeCustomModal", modalId);
    },
    newItemAux(id) {
      return {
        id: id,
        sectionType: null,
        observations: null,
        anexo: null,
        name: null,
        size: null,
        mime: null,
        attachmentId: null,
      };
    },
    newItem() {
      this.newItemFlag = true;
      var id = this.items == null ? 0 : this.items.length;

      let item = this.newItemAux(id);
      this.itemToEdit = item;
      this.openModal("novaDeclaracaoAnexosEdit");
    },
    editItem(item) {
      Object.keys(item).forEach((field) => {
        this.itemToEdit[field] = item[field];
      });
      this.openModal("novaDeclaracaoAnexosEdit");
    },
    async saveAttachment(attachment) {
      let response = await attachmentService.add(attachment);
      if (response.code == 0) {
        attachment.data = null;
        attachment.id = response.data.id;
        attachment.sectionType = this.itemToEdit.sectionType;
        attachment.observations = this.itemToEdit.observations;
        if (
          this.$store.state.declaracao.estado ==
          this.enums.declarationStateTypeEnum.draft
        ) {
          attachment.DeclarationDraftAttachmentId = response.data.id;
          this.saveDraft(attachment);
        } else if (
          this.$store.state.declaracao.estado ==
          this.enums.declarationStateTypeEnum.notPublished
        ) {
          attachment.id = response.data.id;
          attachment.declarationAttachmentId = response.data.id;
        }
        return response.data.id;
      } else {
        utils.errorToast(this.selectedLang.newDeclarationRequestUploadError);
        return null;
      }
    },
    async saveItem() {
      this.validateForm = true;
      if (this.formIsValid) {
        if (this.items == null) {
          this.items = [];
        }
        if (this.itemsToModel == null) {
          this.itemsToModel = [];
        }

        if (this.itemToEdit.declarationDraftAttachmentId == null) {
          this.itemToEdit.declarationDraftAttachmentId =
            await this.saveAttachment(this.itemToEdit.anexo);
        }

        let anexo = toRaw(this.itemToEdit.anexo);
        this.itemToEdit.name = anexo.name;
        this.itemToEdit.size = anexo.size;

        // if(!utils.permittedMimes(anexo.mime)){
        //   utils.warningToast(this.selectedLang.invalidFileFormat);
        //   return;
        // }

        // if(anexo.size > 5000000){
        //   utils.warningToast(this.selectedLang.invalidFileSize);
        //   return;
        // }

        let itemToModel = {
          DeclarationDraftAttachmentId:
            this.itemToEdit.declarationDraftAttachmentId,
          declarationAttachmentId: this.itemToEdit.declarationDraftAttachmentId,
          SectionType: this.itemToEdit.sectionType,
          Observations: this.itemToEdit.observations,
        };

        this.itemsToModel[this.itemToEdit.id] = itemToModel;
        this.items[this.itemToEdit.id] = this.itemToEdit;
        this.cancel();
      }
    },
    saveDraft(attachment) {
      if (
        this.$store.state.declaracao.attachments == null ||
        this.$store.state.declaracao.attachments == undefined
      ) {
        this.$store.state.declaracao.attachments = [];
      }
      this.$store.state.declaracao.attachments.push(attachment);

      let model = declaracaoUnicaHelper.storeToModel();

      if (
        this.$store.state.declaracao.estado ==
        this.enums.declarationStateTypeEnum.draft
      ) {
        declarationService
          .updatedraft(model)
          .then((response) => {
            if (response.code == 0) {
              utils.infoToast(this.selectedLang.declarationDraftSaved);
            }
          })
          .catch(() => {
            utils.errorToast(this.selectedLang.ERR_NETWORK);
          })
          .finally(() => {
            self.loading = false;
          });
      } else if (
        this.$store.state.declaracao.estado ==
        this.enums.declarationStateTypeEnum.notPublished
      ) {
        declarationService
          .update(model)
          .then((response) => {
            if (response.code == 0) {
              utils.infoToast(this.selectedLang.declarationDraftSaved);
            }
          })
          .catch(() => {
            utils.errorToast(this.selectedLang.ERR_NETWORK);
          })
          .finally(() => {
            self.loading = false;
          });
      }
    },
    openDeleteDetail(item) {
      this.idToDelete = item.id;
      this.nameToDelete = item.name;
      this.openModal("novaDeclaracaoAnexosDelete");
    },
    deleteItem() {
      this.items.splice(this.idToDelete, 1);
      this.itemsToModel.splice(this.idToDelete, 1);

      var idReset = 0;
      this.items.forEach((element) => {
        (element.id = idReset), idReset++;
      });

      this.cancel();
    },
    download(attachment) {
      this.loading = true;

      let service = declarationService.getAttachmentByDraft;

      if (
        this.declaracao.estado ==
          this.enums.declarationStateTypeEnum.published ||
        this.declaracao.estado ==
          this.enums.declarationStateTypeEnum.notPublished
      )
        service = declarationService.getAttachmentByDeclaration;

      let id = attachment.declarationDraftAttachmentId;
      if (
        this.$store.state.declaracao.estado !=
        this.enums.declarationStateTypeEnum.draft
      ) {
        id = attachment.declarationAttachmentId;
      }
      service(this.declaracao.draftId, id).then((response) => {
        if (response.code == 0) {
          utils.downloadFile(response.data);
        } else {
          utils.errorToast(this.selectedLang.InternalServerError);
        }

        this.loading = false;
      });
    },
    cancel() {
      this.closeModal("novaDeclaracaoAnexosEdit");
      this.closeModal("novaDeclaracaoAnexosDelete");
      this.nameToDelete = null;
      this.idToDelete = null;
      this.itemToEdit = this.newItemAux(0);
      this.newItemFlag = false;
      this.validateForm = false;
    },
    load() {
      this.itemsToModel = this.declaracao.attachments;

      if (!this.itemsToModel) this.itemsToModel = [];

      let id = 0;
      if (this.itemsToModel.length > 0) {
        this.itemsToModel.forEach((item) => {
          item.DeclarationDraftAttachmentId = item.id;
          item.declarationAttachmentId = item.id;

          this.items.push({
            id: id,
            sectionType: item.sectionType,
            observations: item.observations,
            anexo: null,
            name: item.name,
            size: item.size,
            mime: item.mime,
            declarationDraftAttachmentId: item.id,
            declarationAttachmentId: item.id,
          });

          id++;
        });
      }
    },
    formatBytes(size) {
      return utils.formatBytes(size);
    },
    getFormatFileName(name) {
      return utils.getFormatFileName(name);
    },
    async loadSectionsType() {
      this.loading = true;

      const response = await declarationService.getSectionsType();

      utils.validateResponse(response, null, () => {
        response.data.forEach((element) => {
          this.attachmentsOptions.push({
            label: this.selectedLang.attachmentsOptions[element.value],
            value: element.value,
          });
        });
      });
      this.loading = false;
    },
  },
  computed: {
    tableModel() {
      return {
        headers: [
          {
            title: this.selectedLang.fileName,
            sort: "fileName",
          },
          {
            title: this.selectedLang.size,
            sort: "size",
          },
          {
            title: this.selectedLang.format,
            sort: "format",
          },
          {
            title: this.selectedLang.informationBlock,
            sort: "informationBlock",
          },
          {
            title: this.selectedLang.comments,
            sort: "comments",
          },
        ],
        total: this.tableModelAux.total,
        pages: this.tableModelAux.pages,
      };
    },
    modalTitle() {
      return this.newItemFlag
        ? this.selectedLang.attachmentsModalAdd
        : this.selectedLang.attachmentsModalEdit;
    },
    sectionTypeIsValid() {
      return this.itemToEdit.sectionType != null;
    },
    sectionTypeShowError() {
      return this.validateForm && !this.sectionTypeIsValid;
    },
    anexoIsValid() {
      return this.itemToEdit.anexo != null;
    },
    anexoShowError() {
      return this.validateForm && !this.anexoIsValid;
    },
    formIsValid() {
      return this.sectionTypeIsValid && this.anexoIsValid;
    },
  },
  async mounted() {
    await this.loadSectionsType();
    this.load();
  },
};
</script>
